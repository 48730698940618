<template>
  <div class="meyro-content">
    <div class="contact-button" @click="contactClick">
        CONTACT US
      </div>
    
    <div class="section-top">
      <img src="../../public/assets/images/logo-left.png" class="logo-left" />
      <img src="../../public/assets/images/logo_right.png" class="logo-right" />
      <div class="content-top">
        <div class="subtitle">
          <div>MEYROWITZ BY VISAGES.</div>
          <div>THE COLLECTION.</div>
        </div>
        <div class="title">Where Tradition Meets<br />Avant-Garde Excellence.</div>
        <div class="button-line">
          <a  @click="collectionClick" class="button-white-meyro">See the Collection</a>
          <img src="../../public/assets/images/silmo.png" />
        </div>
      </div>

    

    </div>
    <div class="section-gallery">
      <div class="gallerynew">
        <div class="group">
          <img src="../../public/assets/images/gal1.jpg" />
          <img src="../../public/assets/images/gal2.jpg" />
          <img src="../../public/assets/images/gal3.jpg" />
          <img src="../../public/assets/images/gal4.jpg" />
          <img src="../../public/assets/images/gal5.jpg" />
        </div>
        <div class="group">
          <img src="../../public/assets/images/gal1.jpg" />
          <img src="../../public/assets/images/gal2.jpg" />
          <img src="../../public/assets/images/gal3.jpg" />
          <img src="../../public/assets/images/gal4.jpg" />
          <img src="../../public/assets/images/gal5.jpg" />
        </div>
        <div class="group">
          <img src="../../public/assets/images/gal1.jpg" />
          <img src="../../public/assets/images/gal2.jpg" />
          <img src="../../public/assets/images/gal3.jpg" />
          <img src="../../public/assets/images/gal4.jpg" />
          <img src="../../public/assets/images/gal5.jpg" />
        </div>
      </div>
    </div>
    <div class="section-white">
      <div class="subtitle">
        <div>PUSHING THE BOUNDARIES</div>
        <img src="../../public/assets/images/logomeyro.png" />
        <div>OF LUXURY EYEWEAR</div>
      </div>
      <div class="title">
        Combining The Finest 3D-Printed Materials, Cutting-Edge Technology, & Award-Winning Designs to set a new standard in high-end eyewear.
      </div>
      <div class="cols">
        <div>
          LOUIS OP<br />
          LOUIS INFINI<br />
          LOUIS C<br />
          LOUIS S<br />
          RINGO<br />
          SERGE<br />
        </div>
        <div>
          EDMOND<br />
          DAPHNÉ<br />
          LOUISE<br />
          GABRIEL<br />
          EDDY<br />
          PHILIPPE
        </div>
      </div>
    </div>
    <div class="section collection-products" id="collection">
      <div class="my-slider">
        <div class="row-products">
          <div class="product-item" @click="$router.push({ name: 'collection-glass-meyrowitz', params: { id: 26 } })">
            <div class="img">
              <img src="../../public/assets/images/1.png" />
            </div>
            <div class="bottom">
              <div class="product-title">LOUIS OP</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item" @click="$router.push({ name: 'collection-glass-meyrowitz', params: { id: 25 } })">
            <div class="img">
              <img src="../../public/assets/images/2.png" />
            </div>
            <div class="bottom">
              <div class="product-title">LOUIS INFINITE</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item" @click="$router.push({ name: 'collection-glass-meyrowitz', params: { id: 28 } })">
            <div class="img">
              <img src="../../public/assets/images/3.png" />
            </div>
            <div class="bottom">
              <div class="product-title">LOUIS S</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row-products">
          <div class="product-item" @click="$router.push({ name: 'collection-glass-meyrowitz', params: { id: 27 } })">
            <div class="img">
              <img src="../../public/assets/images/4.png" />
            </div>
            <div class="bottom">
              <div class="product-title">LOUIS C</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/5.png" />
            </div>
            <div class="bottom">
              <div class="product-title">RINGO</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/6.png" />
            </div>
            <div class="bottom">
              <div class="product-title">SERGE</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row-products">
          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/7.png" />
            </div>
            <div class="bottom">
              <div class="product-title">EDMOND</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/8.png" />
            </div>
            <div class="bottom">
              <div class="product-title">DAPHNÉ</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/9.png" />
            </div>
            <div class="bottom">
              <div class="product-title">LOUISE</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row-products">
          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/10.png" />
            </div>
            <div class="bottom">
              <div class="product-title">GABRIEL</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/11.png" />
            </div>
            <div class="bottom">
              <div class="product-title">EDDY</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/12.png" />
            </div>
            <div class="bottom">
              <div class="product-title">PHILIPPE</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>
        </div>
      </div>
     

      <div class="ipad-products">
        <div class="row-products">
          <div class="product-item" @click="$router.push({ name: 'collection-glass-meyrowitz', params: { id: 26 } })">
            <div class="img">
              <img src="../../public/assets/images/1.png" />
            </div>
            <div class="bottom">
              <div class="product-title">LOUIS OP</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item" @click="$router.push({ name: 'collection-glass-meyrowitz', params: { id: 25 } })">
            <div class="img">
              <img src="../../public/assets/images/2.png" />
            </div>
            <div class="bottom">
              <div class="product-title">LOUIS INFINITE</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

         
        </div>
        <div class="row-products">
          <div class="product-item" @click="$router.push({ name: 'collection-glass-meyrowitz', params: { id: 28 } })">
            <div class="img">
              <img src="../../public/assets/images/3.png" />
            </div>
            <div class="bottom">
              <div class="product-title">LOUIS S</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>
          <div class="product-item" @click="$router.push({ name: 'collection-glass-meyrowitz', params: { id: 27 } })">
            <div class="img">
              <img src="../../public/assets/images/4.png" />
            </div>
            <div class="bottom">
              <div class="product-title">LOUIS C</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row-products">
          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/5.png" />
            </div>
            <div class="bottom">
              <div class="product-title">RINGO</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/6.png" />
            </div>
            <div class="bottom">
              <div class="product-title">SERGE</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row-products">
          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/7.png" />
            </div>
            <div class="bottom">
              <div class="product-title">EDMOND</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/8.png" />
            </div>
            <div class="bottom">
              <div class="product-title">DAPHNÉ</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

         
        </div>
        <div class="row-products">
          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/9.png" />
            </div>
            <div class="bottom">
              <div class="product-title">LOUISE</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>
          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/10.png" />
            </div>
            <div class="bottom">
              <div class="product-title">GABRIEL</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row-products">
          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/11.png" />
            </div>
            <div class="bottom">
              <div class="product-title">EDDY</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>

          <div class="product-item">
            <div class="img">
              <img src="../../public/assets/images/12.png" />
            </div>
            <div class="bottom">
              <div class="product-title">PHILIPPE</div>
              <div>
                <img src="../../public/assets/images/edit-grey.png" class="edit" />
                <a class="button-perso" >CUSTOMIZE</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="contenumob">
        <div class="pagination-bottom">
          <div class="pagination" v-if="slider">
            <div :class="{active:currentIndex==0}" @click="goToIndex(0)">1</div>
            <div :class="{active:currentIndex==1}" @click="goToIndex(1)">2</div>
            <div :class="{active:currentIndex==2}" @click="goToIndex(2)">3</div>
            <div :class="{active:currentIndex==3}" @click="goToIndex(3)">4</div>

          </div>
          <div class="arrows">
            <div id="prev"><img src="../../public/assets/images/arrow5.png" width="35" /></div>
            <div id="next"><img src="../../public/assets/images/arrow5.png" width="35" /></div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-story">
      <div class="title">More than Glasses, A Story to Tell.</div>
      <div class="subtitle">Eyewear that reflects individual personalities through exclusive customization & fitting technology. Each frame is a timeless expression of both style and comfort.</div>
    
      <div class="arrows arrowsipad">
          <div id="prev222"><img src="../../public/assets/images/arrow5.png" width="35" /></div>
          <div id="next222"><img src="../../public/assets/images/arrow5.png" width="35" /></div>
        </div>

      <div class="cols my-slider2">
        <div class="col1">
          <div class="img"></div>
          <div class="col-title">Meyrowitz</div>
          <div class="col-text">
            Avec près de 150 ans d’histoire, Meyrowitz se distingue comme l’une des plus anciennes et prestigieuses maisons d’optique au monde, pionnière dans la lunetterie de luxe. Ce nom emblématique incarne un savoir-faire à la française, qui fusionne tradition et innovation. Reconnue pour sa maîtrise du sur-mesure, ses créations furent appréciées par des personnalités de renom telles que Grace Kelly, Claude Monet ou encore Georges Clémenceau. Meyrowitz demeure une institution de renommée mondiale, préservant son héritage au fil du temps grâce à sa quête perpétuelle d’innovation.
          </div>
        </div>
        <div class="col2">
          <div class="img"></div>
          <div class="col-title">Visages</div>
          <div class="col-text">
            Spécialiste des nouvelles technologies dans le secteur de l’optique, Visages offre une nouvelle dimension au sur-mesure lunetier. À travers ses technologies de scan morphologique, d’intelligence artificielle et d’impression 3D, Visages rend possible une individualisation sans précédent, où chaque client devient co-créateur de son propre accessoire.
          </div>
        </div>


      </div>
      <div class="arrows contenumob">
          <div id="prev222"><img src="../../public/assets/images/arrow5.png" width="35" /></div>
          <div id="next222"><img src="../../public/assets/images/arrow5.png" width="35" /></div>
        </div>
    </div>

    <div class="section-half">
     

      <div class="row">
        <div class="left img">

        </div>
        <div class="right text">
          <img src="../../public/assets/images/toparr.png" width="7" class="contenumob arrowlittle" />
          <div class="subtitle">
            AVERAGE WEIGHT: 110G
          </div>
          <div class="title">
            Unique Screw-Less Hinges: Lightweight. Durable. Robust.
          </div>
          <div class="text">
            Deliver Ultimate Comfort For Everyday Wear Without Compromising Strength Or Ease.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="left text">
          <img src="../../public/assets/images/toparr.png" width="7" class="contenumob arrowlittle" />
          <div class="subtitle list">
            <div>ADDITIVE TECHNOLOGY</div>
            <div>3D PRINTING</div>
            <div>ZERO WASTE</div>
          </div>
          <div class="title">
            Shape The Future Of Luxury With Sustainability In Mind.
          </div>
          <div class="text">
            Our Zero-Waste Production Only Uses The Material Needed To Craft A Flawless Eyewear. This Is Cutting-Edge Sustainability.
          </div>
        </div>
        <div class="right img">
          
        </div>
      </div>
      <div class="row">
        <div class="left img">

        </div>
        <div class="right text">
          <img src="../../public/assets/images/toparr.png" width="7" class="contenumob arrowlittle" />
          <div class="title">
            The Bespoke Experience
          </div>
          <div class="text">
            Customize Without Limits & Scan Your Faces For A Tailor-Made Experience
          </div>
          <div class="cols-text">
            <div class="col-text">
              <div class="col-subtitle">
                FACIAL SCAN
              </div>
              <div class="col-text-text">
                Quick and precise scan via mobile app. Automatic capture of morphological and optical measurements.
              </div>
            </div>
            <div class="col-text">
              <div class="col-subtitle">
                CUSTOMIZE WITHOUT LIMITS
              </div>
              <div class="col-text-text">
                Customize your model by choosing from various materials, colors, and engravings on the temple arms.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-grey">
      <div class="subtitle">
        <div>AWARD WINNING DESIGN</div>
        <div>& TECHNOLOGY.</div>
      </div>
      <div class="title">
        The Louis OP, Flagship Model of the Collection. Fully 3D Printed Titanium in One Piece for an iconic look and feel.
      </div>
      <img src="../../public/assets/images/photo6.png" class="img" />
      <br />
      <img src="../../public/assets/images/logos.png" class="logos" />
    </div>

    <div class="section-demo">
      <div class="card">
        <div class="title">
          The Demo Kit
        </div>
        <div class="subtitle">Meyrowitz By Visages Collection</div>
      </div>
    </div>

    <div class="section-footer">
      <img src="../../public/assets/images/totop.png" @click="topClick" class="totop" />
      <div class="left">
        <div class="text">
          <div class="title">
            Transform the eyewear experience with Meyrowitz by Visages
          </div>
          <div class="subtitle">
            where luxury, innovation, & responsibility come together

          </div>
        </div>
        <img src="../../public/assets/images/logos2.png" class="logos contenudesk" />
      </div>
      <div class="right" id="contact">
        <div class="subtitle">CONTACT</div>
        <div class="contact">
          mz@visages.com<br />
          +33678036377 <br />
          5 rue de Castiglione <br />
          75001 Paris

        </div>
      </div>

      <img src="../../public/assets/images/logos2.png" class="logos contenumob" />
    </div>
    
  </div>
</template>



<script>

import { Auth, APIRequest } from "@simb2s/senseye-sdk";
import jQuery  from 'jquery';
import { ContentLoader } from 'vue-content-loader';
import { useMeta } from 'vue-meta'
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider"

export default {
    name: 'Account',
    components: {

        ContentLoader,

    },
    data() {
        return {
            slider2:null,
            slider:null,
            currentIndex:0,
            tinySliderOptions2: {
              container: '.my-slider2',
              mouseDrag: true,
              navPosition:'bottom',
              loop: false,
              items: 1.2,
              gutter: 0,
              swipeAngle: 45,
              nav:true,
              controls:true,
              prevButton:'#prev222',  // Node reference
              nextButton: '#next222',
              startIndex:0,
          },
            tinySliderOptions: {
              container: '.my-slider',
              mouseDrag: true,
              navPosition:'bottom',
              loop: false,
              items: 1,
              gutter: 0,
              swipeAngle: 45,
              nav:true,
              controls:true,
              prevButton:'#prev',  // Node reference
              nextButton: '#next',
              startIndex:0,
          },
        }
    },
    watch: {
       
      
     
    },
    props:["language"],
    methods: {
      handleResize(){
        if(window.innerWidth>760) {

          if(this.slider)
          {
            this.slider.destroy()
          }
         

        }
        else {
          if(!this.slider)
          {
            this.initSlider()
          }
          
        }

        if(window.innerWidth>1024) {

          
          if(this.slider2)
          {
            this.slider2.destroy()
          }

          }
          else {
            if(!this.slider2)
            {
              this.initSlider2()
            }

          }
        
      },
      initSlider()
      {
        if(this.slider)
        {
          this.slider.destroy()
        }
       
        this.slider = tns(
            this.tinySliderOptions
        );
        this.slider.events.on('indexChanged', this.changeIndesxSlider);
        
      },
      initSlider2()
      {
        
        if(this.slider2)
          {
            this.slider2.destroy()
          }
       
        this.slider2 = tns(
            this.tinySliderOptions2
        );
      },
      changeIndesxSlider()
      {
        console.log('index change')
        console.log(this.slider.getInfo().index)
        this.currentIndex=this.slider.getInfo().index
      },
        calculateIndex()
        {
          return this.slider.getInfo().index;
        },

        goToIndex(num)
        {
          this.slider.goTo(num);
        },
        contactClick(){
          jQuery('html, body').animate({
                scrollTop: jQuery("#contact").offset().top
            }, 500); 
        },
        topClick(){
          jQuery('html, body').animate({
                scrollTop: 0
            }, 500); 
        },
        collectionClick(){
          jQuery('html, body').animate({
                scrollTop: jQuery("#collection").offset().top
            }, 500); 
        }
    },
   
  
    created() {

      if(this.language=="fr")
      {

          useMeta({
          title: "VISAGES x Meyrowitz",
          
          htmlAttrs: { lang: this.language, amp: true },
          
      })

      document.querySelector('meta[name="description"]').setAttribute('content',"VISAGES x Meyrowitz.")

      }
      else
      {

      useMeta({
          title: "VISAGES x Meyrowitz",
          
          htmlAttrs: { lang: this.language, amp: true },
          
      })
      document.querySelector('meta[name="description"]').setAttribute('content',"VISAGES x Meyrowitz.")
      }

    },

    async mounted() {
      if(window.innerWidth<=760)
      {

        this.initSlider()

      }
      if(window.innerWidth<=1024)
      {

        this.initSlider2()

      }
     
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style scoped>


@keyframes scrolling {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .section-gallery .gallerynew:hover .group {
      animation-play-state: paused;
    }
 .section-gallery .group {
    display: flex;
    will-change: transform; /* We should be nice to the browser - let it know what we're going to animate. */
    animation: scrolling 10s linear infinite;
    gap: 20px;
    /* Add padding to the right to create a gap between the last and first card. */
    padding-right: 20px;
  }
  .arrowlittle
  {
    margin-bottom:5px;
  }
.section-gallery
{
  padding-top:20px;
  padding-bottom:100px;
}
.section-gallery .gallerynew
{
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}
.section-gallery .gallerynew > * {
      flex: 0 0 100%;
    }
  
.section-gallery .gallerynew img
{
  width:calc(20vw - 23px);
}
.section-footer .totop
{
  position:absolute;
  right:50px;
  width:70px;
  top:50px;
  cursor: pointer;
}
.section-footer .right .contact 
{
  font-size:32px;
}
.section-footer .right
{
  width:30%;
  
}
.section-footer .right .subtitle
{
  font-size:18px;
  margin-bottom:20px;
  color:rgba(255, 255, 255, 0.4);
}
.section-footer .left .subtitle
{
  font-size:60px;
  color:rgba(255, 255, 255, 0.4);
}
.section-footer .left .title
{
  font-size:60px;
}
.section-footer .left .logos 
{
  width:100%;
  margin-top:150px;
}
.section-footer .left
{
  width:70%;
  padding-right:10%;
}
.section-footer
{
  display:flex;
  padding-left:10%;
  line-height:1;
  padding-right:10%;
  padding-top:150px;
  padding-bottom:150px;
  color:#fff;
  position: relative;
  background:#000;
}
.section-demo .card .subtitle
{
  color: rgba(153, 153, 153, 1);
}
.section-demo .card
{
  font-size:42px;
  line-height: 1;
  position:absolute;
  padding:50px 70px;
  right: 10%;
  top:10%;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.section-demo
{
  width:100%;
  background-image:url(../../public/assets/images/demo.jpg);
  position: relative;
  background-size: cover;
  background-position: center center;
  height:100vh;
}
.section-grey .logos
{
  margin-left:auto;
  margin-right: auto;
  width:180px;
}
.section-grey .img
{
  
  
  max-width:950px;
  width:70%;
 
  margin-left:auto;
  margin-right: auto;

}
.section-grey .title
{
  font-size:32px;
  max-width:950px;
  width:70%;
  margin-top:50px;
  margin-left:auto;
  margin-right: auto;
}
.section-grey .subtitle
{
  width:100%;
  align-items: center;
  justify-content: center;
  color:rgba(153, 153, 153, 1);
  font-size:18px;
  display: flex;
}
.section-grey {
  padding-top:150px;
  padding-bottom:180px;
  background:rgba(227, 227, 227, 1);
  padding-left:10%;
  padding-right:10%;
  text-align: center;
}

.row-products .product-item .bottom .edit{
  width:17px;
}
.ipad-products{
  display:none;
}
.row-products .product-item:hover .bottom .edit{
  display:none;
}
.row-products .product-item:hover .bottom .button-perso{
  opacity:1;
}
.row-products .product-item .bottom .button-perso
{
  opacity:0;
  position:absolute;
  right:0;
  top:0;
  display: block;
  background: rgba(153, 153, 153, 1) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color:#fff;
  font-size: 18px;
  text-decoration: none;
  padding:5px 20px;
}
.row-products .product-item .bottom 
{
  display: flex;
  width:90%;
  margin-left:5%;
  margin-top:20px;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.row-products .product-item .bottom .product-title
{
  color: rgba(0, 0, 0, 0.4);
  font-size:18px;
}
.row-products .product-item .img 
{
  background:rgba(227, 227, 227, 1);
  padding:20px;
}
.row-products .product-item
{
  width:32.8%;
  cursor: pointer;
  margin-top:50px;
}
.row-products
{
  display:flex;
  justify-content: space-between;
  padding-left:2%;
  padding-right:2%;
}
.section-white .title
{
  font-size:42px;
  line-height: 1;
  width:90%;
}
.section-white .cols div
{
  margin-right:100px;
  color: rgba(0, 0, 0, 0.4);
  font-size:18px;
  line-height: 1.6;
}
.section-white .cols

{
  margin-top:30px;
  width:80%;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}
.section-white
{
  padding-top:20px;
  padding-bottom:100px;
  padding-left:10%;
  padding-right:10%;
}
.section-white .subtitle img
{
  width:70px;
  position:relative;
  top:-20px;
  margin-left:20px;
  margin-right:20px;
}
.section-white .subtitle
{
  align-items: center;
  display:flex;
  font-size:18px;
  color: rgba(0, 0, 0, 0.4);
}
.content-top .button-line img {
  width:75px;
  margin-left:20px;
}
.content-top .button-line .button-white-meyro
{
  cursor: pointer;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color:#000;
  font-size:18px;
  text-decoration: none;
  padding:10px 40px;
}
.content-top .button-line
{
  margin-top:20px;
  display: flex;
  align-items: center;
}
.content-top .subtitle div {
  margin-right:20px;
}
.content-top .subtitle
{
  font-size:18px;
  display: flex;
}
.content-top .title
{
  font-size:60px;
  line-height:1;
}
.content-top 
{
  position:absolute;
  left:10%;
  bottom:20%;
  color:#fff;
}
.section-top
{
  height:100vh;
  width:100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-image:url(../../public/assets/images/top.jpg);
}
.meyro-content
{
  width:100%;
  padding:0;
  margin:0;
  position: relative;
}
.contact-button
{

  z-index: 9;
  position:fixed;
  right:-20px;
  padding-top:30px;
  font-size:20px;
  padding-left:50px;
  padding-right:70px;
  padding-bottom:30px;
  cursor: pointer;
  bottom:10%;
  border-radius: 10px;
opacity: 1;
color:#fff;
backdrop-filter: blur(30px);
-webkit-backdrop-filter: blur(30px);
  background: rgba(153, 153, 153, 0.75) 0% 0% no-repeat padding-box;
}
.section-story .cols .col2
{
  width:25%;
}
.section-story .cols .col1
{
  margin-right:50px;
  width:40%;
}
.section-story .cols .col-text
{
  font-size:20px;
  margin-top:50px;
}
.section-story .cols .col2 .img
{

  background-image:url(../../public/assets/images/photo2.jpg);
}
.section-story .cols .col1 .img
{

  background-image:url(../../public/assets/images/photo1.jpg);
}
.section-story .cols .img
{
  width:100%;
  height:350px;
  background-size:cover;
  background-position:center center;
}
.section-story .cols .col-title
{
  font-size:40px;
  margin-top:50px;
}
.section-story .cols 
{
  margin-top:150px;
  display: flex;
  width:100%;
  align-self:flex-end; 
  justify-content: flex-end;

}
.section-story .title
{
  font-size:60px;
  line-height: 1;
}
.section-story .subtitle
{
  font-size:60px;
  line-height: 1;
  color: rgba(153, 153, 153, 1);
}
.section-half .row .text .text
{
  font-size:42px;
  color: rgba(153, 153, 153, 1);
}
.section-half .row .text .title
{
  font-size:42px;
}
.section-half .row .text .subtitle
{
  color: rgba(0, 0, 0, 0.4);
  font-size:18px;

  margin-bottom: 50px;
}
.section-half .row .col-text .col-text-text
{
  font-size: 20px;
}
.section-half .row .col-text
{
  width:50%;
  padding-right:20px;
}
.section-half .row .cols-text .col-subtitle
{
  font-size:18px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.4);
}
.section-half .row .cols-text
{
  display: flex;
  width:100%;
  margin-top:50px;
}
.section-half .row .subtitle.list
{
  display: flex;
  justify-content: space-between;
}
.section-half .row .left.text,
.section-half .row .right.text
{
  display: flex;
  padding:5%;
  flex-direction: column;
  line-height:1;
  
  justify-content: center;
}
.section-half .row:first-child .img
{
  background-image:url(../../public/assets/images/photo3.jpg);
}
.section-half .row:nth-child(2) .img
{
  background-image:url(../../public/assets/images/photo4.jpg);
}
.section-half .row:nth-child(3) .img
{
  background-image:url(../../public/assets/images/photo5.jpg);
}
.section-half .row .img
{
  background-size:cover;
  background-position: center center;
}
.section-half .row .left,.section-half .row .right {
  width:50%;
  height:100%;
}
.section-half .row
{
  display:flex;
  height:50vw;
}
.section-half
{
  padding-top:150px;
padding-bottom:0px;
}
.section-story
{
padding-top:200px;
padding-bottom:100px;
padding-left:10%;
padding-right:10%;
}
.logo-left
{
  width:75px;
  position: absolute;
  top:50px;
  left:50px;
}

.logo-right
{
  width:60px;
  position: absolute;
  top:60px;
  right:50px;
}
.section-grey .subtitle div:nth-child(2)
{
  margin-left:15px;
}
@media screen and (max-width:760px)
{
  .section-grey
  {
    padding-top:80px;
    padding-bottom:80px;
  }
  .section-half{
    padding-top:20px;
  }
  .section-story .cols
  {
    margin-top:80px;
  }
  .section-story
  {
    
    padding-top:100px;
  }
  .row-products .product-item .bottom .button-perso
  {
    font-size:10px;
  }
  .row-products .product-item .bottom .product-title
  {
    font-size:14px;
  }
  .section-white
  {
    padding-bottom:20px;
  }
  .section-top
{

  background-image:url(../../public/assets/images/topmob.jpg);
}
  .contenudesk
  {
    display:none;
  }
  .contenumob
  {
    display:block !important;
  }
  .section-footer .totop
  {
    width:40px;
    top:20px;
    right:20px;
  }
  .section-footer .right .contact
  {
    font-size:22px;
  }
  .section-footer .right .subtitle
  {
    font-size:11px;
  }
  .section-footer .left .subtitle
  {
    font-size:30px;
  }
  .section-footer
  {
    padding-top:100px;
    padding-bottom:30px;
  }
  .section-footer .logos
  {
    margin-top:60px;
  }
  .section-footer .right
  {
    width:70%;
    margin-left:30%;
    padding-right:0;
    margin-top:30px;
  }
  .section-footer .left
  {
    width:100%;
    padding-right:0;
  }
  .section-footer
  {
    flex-direction: column;
  }
  .section-demo
  {
    height:70vw;
  }
  .section-demo .card
  {
    padding:20px 30px;
    right:20px;
    top:20px;
  }
  .section-demo .card,.section-demo .card .title 
  {
    font-size:15px !important;
  }
  .section-grey .logos
  {
    width:160px;
  }
  .section-grey .img
  {
    width:100%;
  }
  .section-grey .title
  {
    width:100%;
    font-size:18px !important;
  }
  .logo-left
  {
    width:35px;
    top:20px;
    left:20px;
  }
  .logo-right
  {
    width:30px;
    top:30px;
    right:20px;

  }
  .content-top .subtitle
  {
    margin-bottom:10px;
    font-size:10px;
  }
  .content-top .button-line .button-white-meyro
  {
    font-size:16px;
  }
  .section-gallery
  {
    margin-top:100vw;
    transform:scale(5);
  }
  .section-gallery .gallerynew img
  {
    width:calc(20vw - 20px);
  }
  .content-top .button-line img
  {
    width:42px;
  }
  .section-white .subtitle img
  {
    width:35px;
    top:-10px;
  }
  .section-story
  {
    padding-left:5%;
    padding-right:5%;
  }
  .section-white .cols
  {
    width:100%;
  
  }
  .section-story .cols .col-text
  {
    font-size:14px;
    margin-top:20px;
  }
  .section-half .row .right.img,
  .section-half .row .left.img
  {
    order:1;
  }

  .section-half .row .col-text .col-text-text
  {
    font-size:17px;
  }
  .section-half .row .cols-text .col-subtitle
  { 
    font-size:11px;
  }
  .section-half .row .col-text
  {
    padding-right:0;
    width:100%;
  }
  .section-half .row .text .subtitle
  {
    font-size:11px;
  }
  .section-half .row .text .title,
  .section-half .row .text .text
  {
    font-size:24px !important;
  }
  .section-half .row .cols-text
  {
    flex-direction: column;
    margin-top: 20px;
  }
  .section-half .row .left.text,
  .section-half .row .right.text
  {
    order:2;
    padding-left:10%;
    padding-right:10%;
  }
  .section-half .row .left, .section-half .row .right
  {
    width:100vw;
    height:100vw;
  }
  .section-half .row
  {
    height:auto;
    flex-direction: column;
  }
  .section-grey .subtitle
  {
    font-size:11px;
  }
  .section-half .row .col-text:nth-child(2)
  {
    margin-top:20px;
  }
  .section-story .cols .col-title
  {
    font-size:28px;
    margin-top:20px;
  }
  .section-story .cols .col2
  {
    width:100%;
   
  }
  .section-story .cols .col1
  {
    width:100%;
    margin-right:10px;
  }

  #prev222[aria-disabled='true'],#next222[aria-disabled='true']
  {
      opacity:0.4;
  }

  #prev222 img 
  {
    transform: rotate(180deg);
  }
  #prev[aria-disabled='true'],#next[aria-disabled='true']
  {
      opacity:0.4;
  }

  #prev img 
  {
    transform: rotate(180deg);
  }
  .section-story .cols
  {
    justify-content: center;
  }
  .section-story .subtitle
  {
    font-size:30px;
  }
  .row-products .product-item
  {
    width:100%;
    padding-left:5%;
    padding-right:5%;
  }
  .my-slider
  {
   
    
  }
  .row-products
  {
    padding-bottom:50px;
    padding-left:0%;
    padding-right:0%;
    flex-direction: column;
  }
  .section-white .title
  {
    width:100%;
  }
  .section-white .cols div
  {
    margin-right:20px;
    font-size:10px;
  }
  .contact-button
  {
    font-size:10px;
    padding-top: 20px;

    padding-left: 30px;
    padding-right: 50px;
    padding-bottom: 20px;
  }
  .section-white .subtitle
  {
    font-size:10px;
  }
  .pagination-bottom .pagination div.active
  {
    font-weight: 600;
  }
  .pagination-bottom .pagination div 
  {
    margin-right:15px;
  }
  .pagination-bottom .pagination 
  {
    display:flex;
    font-size:14px;
  }
  .section-story .arrows #prev222
  {
    margin-right:15px;
  }
  .section-story .arrows
  {
    margin-top:50px;
    width:90%;
    margin-left:5%;
    justify-content: flex-end;
    align-items: flex-end;
    display:flex !important;
  }
  .pagination-bottom .arrows #prev 
  {
    margin-right:15px;
  }
  .pagination-bottom .arrows
  {
    display:flex;
  }
  .pagination-bottom
  {
    padding-left:5%;
    margin-top:0px;
    align-items: flex-end;
    padding-right:5%;
    display:flex;
    justify-content: space-between;
  }
}
.arrowsipad
  {
    display:none;
  }
@media screen and (min-width:760px) and (max-width:1024px)
{
  .logo-left 
  {
    top:20px;
    left:30px;
    width:65px;
  }
  .logo-right
  {
    right:30px;
    top:35px;
    width:50px;
  }
  .my-slider 
  {
    display:none !important;
  }
  .ipad-products
  {display:block !important;}
  .section-half .row .col-text .col-text-text
  {
    font-size:17px;
  }
.section-half .row .text .title,
.section-half .row .text .text
{
  font-size:24px !important;
}
.section-half .row .text .subtitle
{
  font-size:11px;
}
.section-half .row .col-text
{
  padding-right:0;
  width:100%;
}
.section-half .row .cols-text
{
  flex-direction: column;
}
.section-gallery
  {
    margin-top:40vw;
    transform:scale(3);
  }
  .section-gallery .gallerynew img
  {
    width:calc(20vw - 20px);
  }
  .section-white
  {
    padding-top: 150px;
  }
  .section-white .cols,
  .section-white .title {
    width:100%;
  }
  .section-footer .left,
  .section-footer .right {
    width:100%;
  }
  .section-footer
  {
    flex-direction: column;
  }
  .logos.contenudesk {
    display:none;
  }
  .logos.contenumob
  {
    display:block !important;
    margin-top:100px;
  }
  .section-white .subtitle
  {

  }

  .section-footer .right
  {
    padding-top:50px;
    padding-left:60%;
  }
  .section-footer
  {
    padding-bottom: 50px;
  }
  .section-top
  {
    background-position: 70% center;
  }
  .section-footer .right .contact
  {
    font-size:24px;
  }
  .section-footer .left .title,.section-footer .left .subtitle

  {
    font-size:34px !important;
  }
  .section-demo
  {
    height:70vw;
  }
  .section-demo .card .subtitle
  {
    font-size:30px;
  }
  .row-products .product-item
  {
    width:49%;
  }
  .section-grey .img,
  .section-grey .title
  {
    width:100%;
  }
  .section-story .title,
  .section-story .subtitle
  {
    font-size:34px !important;
  }
  .arrowsipad #prev222
  {
    margin-right:20px;
  }
  .section-story .cols
  {
    margin-top:50px;
  }
  .arrowsipad
  {
    justify-content: flex-end;
    display:flex;
    margin-top:30px;
    align-items: flex-end;
  }

  #prev222[aria-disabled='true'],#next222[aria-disabled='true']
  {
      opacity:0.4;
  }

  #prev222 img 
  {
    transform: rotate(180deg);
  }
}
</style>